import { ChainId } from '@pancakeswap/chains';
import { getViemClients } from 'utils/viem';
import { multicallABI } from 'config/abi/Multicall';
import { getMulticallAddress } from 'utils/addressHelpers';
import { Call } from './actions';
import { RetryableError } from './retry';

const l2DifferentBlockNumberChains = [
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPBNB_TESTNET,
  ChainId.OPBNB,
];

export type FetchChunkResult = ReturnType<typeof fetchChunk>;

export async function fetchChunk(
  chainId: number,
  chunk: Call[],
  minBlockNumber: number,
): Promise<{ results: any[]; blockNumber: number }> {
  let resultsBlockNumber: bigint | undefined;
  let returnData: any;
  const client = getViemClients({ chainId });
  try {
    [resultsBlockNumber, , returnData] = await client.readContract({
      abi: multicallABI,
      address: getMulticallAddress(chainId),
      functionName: 'tryBlockAndAggregate',
      args: [false, chunk.map((obj) => ({ callData: obj.callData, target: obj.address }))],
      blockNumber: BigInt(minBlockNumber),
    });

    // Log the returned data for debugging purposes
    returnData.forEach((data: any, index: number) => {
      if (!data.success || data.returnData === '0x') {
        console.error('Failed call:', chunk[index], 'with data:', data.returnData);
      }
    });

  } catch (err) {
    const error = err as any;
    if (
      error.code === -32000 ||
      (error?.data?.message && error?.data?.message?.indexOf('header not found') !== -1) ||
      error.message?.indexOf('header not found') !== -1
    ) {
      throw new RetryableError(`header not found for block number ${minBlockNumber}`);
    } else if (error.code === -32603 || error.message?.indexOf('execution ran out of gas') !== -1) {
      if (chunk.length > 1) {
        if (process.env.NODE_ENV === 'development') {
          console.debug('Splitting a chunk in 2', chunk);
        }
        const half = Math.floor(chunk.length / 2);
        const [c0, c1] = await Promise.all([
          fetchChunk(chainId, chunk.slice(0, half), minBlockNumber),
          fetchChunk(chainId, chunk.slice(half, chunk.length), minBlockNumber),
        ]);
        return {
          results: c0.results.concat(c1.results),
          blockNumber: c1.blockNumber,
        };
      }
    }
    console.debug('Failed to fetch chunk inside retry', error);
    throw error;
  }

  const l2DifferentBlockNumber = l2DifferentBlockNumberChains.includes(chainId);

  if (Number(resultsBlockNumber) < minBlockNumber && !l2DifferentBlockNumber) {
    console.debug(`Fetched results for old block number: ${resultsBlockNumber?.toString()} vs. ${minBlockNumber}`);
  }

  return {
    results: returnData,
    blockNumber: l2DifferentBlockNumber ? minBlockNumber : Number(resultsBlockNumber),
  };
}

export async function fetchChunkWithRetry(
  chainId: number,
  chunk: Call[],
  minBlockNumber: number,
): Promise<{ results: any[]; blockNumber: number }> {
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second

  const retryFetchChunk = async (attempt: number): Promise<{ results: any[]; blockNumber: number }> => {
    try {
      return await fetchChunk(chainId, chunk, minBlockNumber);
    } catch (error) {
      if (attempt < MAX_RETRIES) {
        console.warn(`Attempt ${attempt} failed. Retrying in ${RETRY_DELAY}ms...`, error);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return retryFetchChunk(attempt + 1);
      }
      console.error(`All ${MAX_RETRIES} attempts failed.`, error);
      return {
        results: chunk.map(() => ({ success: false, returnData: '0x' })), // Return fallback data
        blockNumber: minBlockNumber,
      };
    }
  };

  return retryFetchChunk(1);
}
