import {
  Flex,
  FlexGap,
  NextLinkFromReactRouter,
  Text,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { NetworkSwitcher } from 'components/ENetworkSwitcher'
import { useTranslation } from '@pancakeswap/localization'
// import { formatBigNumber, getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { useCallback, useMemo, useState } from 'react'
import { useInterval } from '@pancakeswap/hooks'
import { useV2BurnerContract } from 'hooks/useContract'
import { CAKE, NINEINCH } from '@pancakeswap/tokens'
import { ChainId } from '@pancakeswap/chains'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useTokenBalances } from 'state/wallet/hooks'
import useTotalSupply from 'hooks/useTotalSupply'
import { CurrencyLogo } from 'components/Logo'
import { CurrencyAmount } from '@pancakeswap/swap-sdk-core'
import { v2InfoClients, v3InfoClients } from 'utils/graphql'
import { fetchTotalValueLockedV2, fetchTotalValueLockedV3 } from 'views/V3Info/data/pool/poolData'
import { useTokenData } from 'views/V3Info/hooks'
import UserMenu from '../UserMenu'

const MenuContainer = styled.div`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: black;
  // font-size: 12px;
  // font-weight: 700;
  // border-right: 1px solid #72e9c1;
  padding: 0 16px;
  z-index: 1;

  @media screen and (max-width: 851px) {
    height: 76px;
  }
`

const BrandImage = styled.img`
  width: 100px;
  object-fit: contain;

  @media screen and (max-width: 499px) {
    display: none;
  }
`
const MobileBrandImage = styled.img`
  width: 30px;
  object-fit: contain;
  flex-grow: 1;
  @media screen and (min-width: 500px) {
    display: none;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  position: relative;
  .submenu {
    position: absolute;
    display: none;
    margin: 0;
    padding: 0.5em 0;
    border: 2px solid #fff2;
    border-radius: 16px;
    min-width: 15em;
    text-transform: uppercase;
  }

  &:hover {
    .submenu {
      display: flex;
    }
  }

  > a {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-radius: 15px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textSubtle};

    &:hover {
      background-color: ${({ theme }) => theme.colors.tertiary};
    }

    &.active {
      color: rgb(48, 167, 95);
      font-weight: bold;
    }

    &.link-v2 {
      gap: 6px;
      &::after {
        background: #0F0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
      }
    }

    & .active-icon {
      fill: ${({ theme }) => theme.colors.primary};
      position: absolute;
      left: 10px;
      top: 6px;
    }
  }


  @media screen and (max-width: 851px) {
    display: none;
  }
`

const SubMenuItem = styled.div`
  cursor: pointer;
  position: relative;
  padding: 1em;
  color: ${({ theme }) => theme.colors.textSubtle};

  &:hover {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const SubMenuActive = styled.div`
  background: white;
  width: 4px;
  height: 4px;
  border-radius: 15px;
  position: absolute;
  top: 8px;
  left: -8px;
`

const SocialLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px;

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const MobileMenu = styled(Flex)`
  gap: 4px;

  @media screen and (min-width: 851px) {
    display: none;
  }
`
const DesktopMenu = styled(Flex)`
  gap: 4px;
  flex-direction: row;
  margin: 10px 2px;
  justify-content: space-around;

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const Highlights = styled.span`
  color: #01FF00;
  font-style: italic;
  vertical-align: super;
  font-size: x-small;
`

const LinkComponent = (linkProps: any) => {
  return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const formatNumber = (value, precision = 6) => {
  if (!value) return '0'

  const k = 1000
  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']

  const i = value < 1 ? 0 : Math.floor(Math.log(value) / Math.log(k))

  return `${parseFloat((value / k ** i).toFixed(i ? 2 : precision))}${sizes[i]}`
}

const LeftMenu = ({ menuItems, activeItem, activeSubItem }) => {
  const { t } = useTranslation()
  // const { chainId } = useActiveChainId()
  const [totalLiquidity, setTotalLiquidity] = useState(0)
  const [totalUSDValue, setTotalUSDValue] = useState(0)
  const totalVauleLocked = useMemo(() => totalLiquidity + (totalUSDValue ?? 0), [totalLiquidity, totalUSDValue])

  const tokenData = useTokenData(NINEINCH[ChainId.PULSE].address.toLowerCase(), ChainId.PULSE)
  const contractBurner = useV2BurnerContract()
  const burnt9INCH = useSingleCallResult({
    contract: contractBurner,
    functionName: 'burnedNineInch'
  })
  const burntBBC = useSingleCallResult({
    contract: contractBurner,
    functionName: 'burnedBBC'
  })
  const deadBalances = useTokenBalances('0x000000000000000000000000000000000000dEaD', [NINEINCH[ChainId.PULSE], CAKE[ChainId.PULSE]])
  const pulseBalances = useTokenBalances('0x0000000000000000000000000000000000000369', [NINEINCH[ChainId.PULSE], CAKE[ChainId.PULSE]])
  const supply9INCH = useTotalSupply(NINEINCH[ChainId.PULSE])
  const supplyBBC = useTotalSupply(CAKE[ChainId.PULSE])
  const percent9inchBurn = useMemo(() => 
    supply9INCH 
    ? CurrencyAmount.fromRawAmount(NINEINCH[ChainId.PULSE], String(burnt9INCH?.result ? burnt9INCH.result : 0))
        .add(deadBalances && deadBalances[NINEINCH[ChainId.PULSE].address] ? deadBalances[NINEINCH[ChainId.PULSE].address]! : CurrencyAmount.fromRawAmount(NINEINCH[ChainId.PULSE], '0'))
        .add(pulseBalances && pulseBalances[NINEINCH[ChainId.PULSE].address] ? pulseBalances[NINEINCH[ChainId.PULSE].address]! : CurrencyAmount.fromRawAmount(NINEINCH[ChainId.PULSE], '0'))
        .multiply(10 ** 20).divide(supply9INCH) 
    : undefined, 
  [burnt9INCH, supply9INCH, deadBalances, pulseBalances])
  const percentBBCBurn = useMemo(() => 
    supplyBBC 
    ? CurrencyAmount.fromRawAmount(CAKE[ChainId.PULSE], String(burntBBC?.result ? burntBBC.result : 0))
        .add(deadBalances && deadBalances[CAKE[ChainId.PULSE].address] ? deadBalances[CAKE[ChainId.PULSE].address]! : CurrencyAmount.fromRawAmount(CAKE[ChainId.PULSE], '0'))
        .add(pulseBalances && pulseBalances[CAKE[ChainId.PULSE].address] ? pulseBalances[CAKE[ChainId.PULSE].address]! : CurrencyAmount.fromRawAmount(CAKE[ChainId.PULSE], '0'))
        .multiply(10 ** 20).divide(supplyBBC) 
    : undefined, 
  [burntBBC, supplyBBC, deadBalances, pulseBalances])

  const fetchData = useCallback(() => {
    Promise.all([
      fetchTotalValueLockedV2(v2InfoClients[ChainId.PULSE]),
      fetchTotalValueLockedV2(v2InfoClients[ChainId.ETHEREUM]),
      fetchTotalValueLockedV3(v3InfoClients[ChainId.PULSE]),
    ]).then(([v2PulseTVL, v2EthTVL, v3TVL]) => {
      const total = v2PulseTVL + v2EthTVL + v3TVL
      setTotalLiquidity(total)
    })
    fetch(`https://stats.9inch.io/get9inchStats`).then(res => res.json()).then((data: any) => {
      const usdPoolStaked: number = Object.values(data).reduce((sum: number, c: any) => {
        return sum + (c.totalPoolsStakedUSD ?? 0) + (c.totalBBCPoolsStakedUSD ?? 0) + (c.totalFarmsLiquidityUSD ?? 0) + (c.totalPrcFarmsLiquidityUSD ?? 0)
      }, 0)
      setTotalUSDValue(usdPoolStaked)
    }).catch(ex => console.error(ex))
  }, [])

  useInterval(fetchData, 1000 * 60, true, true)

  return (
    <MenuContainer>
      <Flex justifyContent="space-between">
        <BrandImage src="/assets/nav-title-dark.png" />
        <MobileBrandImage src="/assets/nav-title-light.png" />
      </Flex>

      <Flex flexGrow={1} ml="8px">
        {menuItems.map((item) => (
          <MenuItem key={item.label}>
            <LinkComponent href={item.href} className={item === activeItem ? "active" : ""} disable={!!item.items}>
              {item.label}
            </LinkComponent>
            {item.items ? (
              <Flex className="submenu" flexDirection="column">
                {item.items.map((subItem) => (
                  <SubMenuItem key={subItem.label}>
                    <LinkComponent href={subItem.href} style={{ position: 'relative' }}>
                      {/* {subItem === activeSubItem && <SubMenuActive />} */}
                      {subItem.label}
                      {subItem.highlights && (<Highlights>{subItem.highlights}</Highlights>)}
                    </LinkComponent>
                  </SubMenuItem>
                ))}
              </Flex>
            ) : null}
          </MenuItem>
        ))}
        <MenuItem>
          <LinkComponent href="https://app.9inch.io" className="link-v2">
            9INCH v2
          </LinkComponent>
        </MenuItem>
      </Flex>

      <DesktopMenu>
        <FlexGap alignItems="center" mr="32px" gap="10px">
          <Text color="primary" fontSize="15px">
            {t('TVL: ')}
          </Text>
          <Text fontSize={14} color="white">
            ${formatNumber(totalVauleLocked, 2)}
          </Text>
        </FlexGap>
        <FlexGap alignItems="center" mr="32px" gap="10px">
          <CurrencyLogo currency={NINEINCH[ChainId.PULSE]}/>
          <Text fontSize={14} color="white">
            {percent9inchBurn?.toFixed(2) ?? 0}% 🔥
          </Text>
        </FlexGap>
        <FlexGap alignItems="center" mr="32px" gap="10px">
          <CurrencyLogo currency={CAKE[ChainId.PULSE]}/>
          <Text fontSize={14} color="white">
            {percentBBCBurn?.toFixed(2) ?? 0}% 🔥
          </Text>
        </FlexGap>
        {
          !!tokenData?.priceUSDChange &&
          <FlexGap alignItems="center" mr="32px" gap="10px">
            <CurrencyLogo currency={NINEINCH[ChainId.PULSE]}/>
            <Text fontSize={14} color={tokenData.priceUSDChange > 0 ? "success" : "failure"}>
              {tokenData.priceUSDChange > 0 ? "↑" : "↓"}
              {tokenData.priceUSDChange.toFixed(2) ?? 0}%
            </Text>
          </FlexGap>
        }
        <NetworkSwitcher />
        <UserMenu />
      </DesktopMenu>

      <MobileMenu>
        <NetworkSwitcher />
        <UserMenu />
      </MobileMenu>

      {/* <SocialLinks>
        <DiscordIcon href="" target="_blank" cursor="pointer" />
        <TelegramIcon href="" target="_blank" cursor="pointer" />
        <TwitterIcon href="" target="_blank" cursor="pointer" />
        <GithubIcon href="" target="_blank" cursor="pointer" />
        <MediumIcon href="" target="_blank" cursor="pointer" />
      </SocialLinks> */}
    </MenuContainer>
  )
}

export default LeftMenu
