import { ContextApi } from '@pancakeswap/localization'
import {
  DropdownMenuItems,
  EEarnIcon,
  EExchangeIcon,
  EXNineinchIcon,
  EarnFillIcon,
  EarnIcon,
  MenuItemsType,
  MoreIcon,
  PancakeProtectorIcon,
  SwapFillIcon,
  SwapIcon,
  TrophyIcon,
} from '@pancakeswap/uikit'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        icon: EExchangeIcon,
        href: '/swap',
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          {
            label: t('9INCH V2'),
            href: 'https://app.9inch.io',
            showItemsOnMobile: true,
          },

        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Liquidity'),
        href: '/add/PLS/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f?chain=pulse',
        showItemsOnMobile: true,
        icon: EEarnIcon,
        items: [
          {
            label: t('Add Liquidity'),
            href: '/add/PLS/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f?chain=pulse',
            showItemsOnMobile: true,
          },
          {
            label: t('Positions'),
            href: '/positions',
            showItemsOnMobile: true,
          },
          {
            label: t('Stableswap '),
            highlights: "NEW!",
            href: '/add/0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
            showItemsOnMobile: true,
          },
        ]
      },
      {
        label: t('Stats'),
        href: '/info',
        icon: TrophyIcon,
        showItemsOnMobile: false,
        items: [
          {
            label: t('Stats'),
            href: '/info',
          },
          {
            label: t('Earnings dashboard'),
            href: '/xnineinch',
          },
          /* {
            label: t('Gauge'),
            href: '/gauge',
          }, */
          {
            label: t('Launchpad'),
            href: '/launchpad',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
